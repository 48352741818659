import React, { useState, useEffect, useRef } from 'react';
import { Server, Code, Cpu, User, Monitor, Loader, Terminal, Sun, Moon, Clock } from 'react-feather';
import './HomePage.css';

const HomePage = () => {
  const [text, setText] = useState('');
  const [command, setCommand] = useState('');
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textComplete, setTextComplete] = useState(false);
  const [theme, setTheme] = useState('dark');
  const [stats, setStats] = useState({ users: 0, projects: 0 });
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const fullText = "Iniciando sistemas de Mirt...";
  const terminalRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    let i = 0;
    const typing = setInterval(() => {
      setText(fullText.slice(0, i + 1));
      i++;
      if (i >= fullText.length) {
        clearInterval(typing);
        setTextComplete(true);
        setTimeout(() => {
          setOutput(prev => [...prev, { type: 'system', content: 'Acceso concedido. Sistema en línea. Ingrese "ayuda" para comandos disponibles.' }]);
        }, 500);
      }
    }, 100);

    const interval = setInterval(() => {
      setStats(prevStats => ({
        users: prevStats.users + Math.floor(Math.random() * 3),
        projects: prevStats.projects + Math.floor(Math.random() * 2)
      }));
    }, 5000);

    return () => {
      clearInterval(typing);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    if (loading) {
      timerRef.current = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setElapsedTime(0);
    }
    return () => clearInterval(timerRef.current);
  }, [loading]);

  const handleCommand = async (e) => {
    e.preventDefault();
    const cmd = command.toLowerCase().trim();
    if (cmd) {
      setOutput(prev => [...prev, { type: 'user', content: `> ${command}` }]);
      setLoading(true);
      setStartTime(new Date());

      let responseText = '';
      let isAIResponse = false;
      if (cmd === 'tema') {
        setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
        responseText = `Tema cambiado a ${theme === 'dark' ? 'claro' : 'oscuro'}.`;
      } else if (cmd === 'stats') {
        responseText = `Usuarios conectados: ${stats.users} | Proyectos activos: ${stats.projects}`;
      } else if (cmd === 'ayuda') {
        responseText = 'Comandos: proyectos, sobre, contacto, minecraft, tema, stats, [mensaje]';
      } else if (cmd === 'proyectos') {
        responseText = 'Proyecto actual: Hostage Club - Servidor de Minecraft con IA avanzada';
      } else if (cmd === 'sobre') {
        responseText = 'Mirt: Innovador en IA y tecnología, creador de experiencias digitales únicas.';
      } else if (cmd === 'contacto') {
        responseText = 'Enlace neural: mirt@mirt.one | Nodo principal: mirt.one';
      } else if (cmd === 'minecraft') {
        responseText = 'Hostage Club: Acceso vía mc.hostage.club | Portal: hostage.club';
      } else {
        isAIResponse = true;
        try {
          const response = await fetch('https://mirt.sytes.net:5000/api/generate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt: cmd }),
          });
          const data = await response.json();
          if (response.ok) {
            responseText = data.generated_text;
          } else {
            responseText = `Error: ${data.error}`;
          }
        } catch (error) {
          responseText = `Error: ${error.message}`;
        }
      }

      const endTime = new Date();
      const responseTime = endTime - startTime;

      if (responseText) {
        setOutput(prev => [...prev, { 
          type: 'system', 
          content: responseText,
          responseTime: isAIResponse ? responseTime : null
        }]);
      }

      setLoading(false);
      setCommand('');
    }
  };

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gradient-to-br from-black via-purple-900 to-blue-900' : 'theme-light'} ${theme === 'dark' ? 'text-cyan-300' : 'text-blue-800'} font-mono p-4 relative overflow-hidden flex flex-col items-center`}>
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMDAwIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiMyMjIiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-10"></div>
      
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div key={i} className="absolute w-1 h-1 bg-cyan-400 rounded-full animate-float" style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 5}s`,
          }}></div>
        ))}
      </div>
      
      <header className="mb-8 relative z-10 text-center">
        <div className="flex items-center justify-center mb-4">
          <Terminal className="text-cyan-400 mr-3" size={48} />
          <h1 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-500 
                         shadow-lg shadow-cyan-500/50 p-2 rounded-lg 
                         border-2 border-cyan-500 inline-block">
            Mirt.one
          </h1>
        </div>
        <div className="typewriter-container">
          <p className={`text-xl ${theme === 'dark' ? 'text-cyan-200' : 'text-blue-800'} ${textComplete ? '' : 'typewriter'}`}>{text}</p>
        </div>
      </header>

      <div className="mb-4 flex justify-between w-full max-w-3xl">
        <div className={`${theme === 'dark' ? 'text-cyan-400' : 'text-blue-800'} text-sm`}>
          Usuarios: {stats.users} | Proyectos: {stats.projects}
        </div>
        <button onClick={toggleTheme} className="focus:outline-none z-20">
          {theme === 'dark' ? <Sun className="text-yellow-400" /> : <Moon className="text-blue-600" />}
        </button>
      </div>

      <main className="mb-8 relative z-10 w-full max-w-3xl">
        <div ref={terminalRef} className={`terminal border ${theme === 'dark' ? 'border-cyan-500 bg-black bg-opacity-50' : 'border-blue-800 bg-white bg-opacity-75'} rounded-lg p-4 h-96 overflow-y-auto mb-4 backdrop-blur-sm shadow-lg ${theme === 'dark' ? 'shadow-cyan-500/50' : 'shadow-blue-800/50'}`}>
          {output.map((line, index) => (
            <div key={index} className="mb-1 flex items-start">
              {line.type === 'user' ? (
                <User className="terminal-icon mr-2 text-green-400" />
              ) : (
                <Monitor className="terminal-icon mr-2 text-cyan-300" />
              )}
              <div>
                <p className={`${line.type === 'user' ? 'user-message' : 'system-message'} ${theme === 'dark' ? (line.type === 'user' ? 'text-green-400' : 'text-cyan-300') : (line.type === 'user' ? 'text-green-700' : 'text-blue-600')}`} 
                   dangerouslySetInnerHTML={{ __html: line.content }}>
                </p>
                {line.responseTime && (
                  <span className="response-time">
                    <Clock size={12} />
                    {(line.responseTime / 1000).toFixed(2)}s
                  </span>
                )}
              </div>
            </div>
          ))}
          {loading && (
            <div className="flex items-center text-cyan-300">
              <Loader className="animate-spin mr-2" />
              <p>Pensando...</p>
              <span className="thinking-time">
                <Clock size={12} />
                {elapsedTime}s
              </span>
            </div>
          )}
        </div>
        <form onSubmit={handleCommand} className="flex w-full max-w-3xl mb-4">
          <input
            type="text"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            disabled={loading}
            className={`flex-grow ${theme === 'dark' ? 'bg-black bg-opacity-50 border-cyan-500 text-cyan-300' : 'bg-white bg-opacity-50 border-blue-800 text-blue-800'} p-2 rounded-l-md focus:outline-none focus:ring-2 ${theme === 'dark' ? 'focus:ring-cyan-600' : 'focus:ring-blue-600'} transition-all duration-300 ease-in-out`}
            placeholder="Ingresa un comando..."
          />
          <button type="submit" disabled={loading} className={`bg-cyan-600 text-black px-4 py-2 rounded-r-md transition-all duration-300 ease-in-out ${loading ? 'cursor-not-allowed bg-cyan-400' : 'hover:bg-cyan-500 hover:shadow-lg hover:shadow-cyan-500/50'}`}>
            Ejecutar
          </button>
        </form>
      </main>

      <footer className="text-center relative z-10 w-full">
        <div className="flex justify-center space-x-6 mb-4">
          <Server className="text-cyan-400 pulse-icon" />
          <Code className="text-cyan-400 pulse-icon" />
          <User className="text-cyan-400 pulse-icon" />
          <Cpu className="text-cyan-400 pulse-icon" />
        </div>
        <p className={`${theme === 'dark' ? 'text-cyan-200' : 'text-blue-800'}`}>&copy; 2024 Mirt.one | Forjado con IA y pasión por la innovación</p>
      </footer>
    </div>
  );
};

export default HomePage;